import { variables } from "../variables";

const urls = {
  'shop/courses-by-hazard/office': 'Office Safety Courses',
  'shop/cpd-accredited': 'CPD Accredited Courses',
  'shop/first-aid': 'First Aid Courses',
  'shop/food-safety': 'Food Safety Courses',
  'shop/oil-and-gas': 'Oil & Gas Courses',
  'shop/healthcare/': 'Healthcare Courses',
  'shop/hse-management/': 'HSE Management Courses',
  'shop/driving/': 'Transport Safety Courses',
  'shop/warehouse/': 'Warehouse Safety Courses',
  'shop/courses-by-industry/construction/': 'Construction Courses',
}
const currentUrl = window.location;

// Set page title and highlight selected item
if (currentUrl.pathname.includes('shop')) {
  function assignTitle(title) {
    const titleElement = document.querySelector('.echo-shop-page-title');
    if (titleElement) {
      titleElement.innerHTML = title;
      return true; // Successfully updated
    }
    return false; // Element not found
  }

  function setTitle() {
    const urlKeyWhichUserIsOn = Object.keys(urls).find((item) =>
      currentUrl.pathname.includes(item)
    );
    const title = urlKeyWhichUserIsOn
      ? urls[urlKeyWhichUserIsOn]
      : 'Echo3 Online Courses';
    return assignTitle(title);
  }

  // Try setting the title immediately
  if (!setTitle()) {
    // If the element doesn't exist, use MutationObserver to wait for it
    const observer = new MutationObserver(() => {
      if (setTitle()) {
        observer.disconnect(); // Stop observing once the title is set
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }


  document.addEventListener('DOMContentLoaded', function() {
    const categories = document.querySelector('.course-categories-container')
    if(categories){
      const anchors = Array.from(categories.getElementsByTagName('a'));
      if (anchors) {
        const link = anchors.find(anchor => anchor.getAttribute('href').includes(currentUrl.pathname)); // or provide a better regex pattern using .match()
        if (link) {
          link.closest(".wp-block-button").style.backgroundColor = variables.primaryColor;
          link.style.cssText = `color: white !important; background-color: ${variables.primaryColor} !important`;
        }
      }
    }
  });
}

