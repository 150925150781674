document.addEventListener('DOMContentLoaded', () => {
  console.log('yooo')
  const courseRows = document.querySelectorAll('.courses-table tr');
  if (courseRows.length > 0) {
    courseRows.forEach((row) => {
      const moreInfoSpan = row.querySelector('span');
      if (moreInfoSpan) {
        moreInfoSpan.addEventListener('click' , () => {
          console.log('helo click')
          let accordion = row.querySelector('.accordion-content');
          if (accordion) {
            // Toggle visibility
            if (accordion.style.maxHeight && accordion.style.maxHeight !== "0px") {
              accordion.style.maxHeight = "0";
              accordion.style.padding = "0";
              moreInfoSpan.classList.remove('open');
            } else {
              accordion.style.maxHeight = accordion.scrollHeight + "px";
              accordion.style.padding = "1rem";
              moreInfoSpan.classList.add('open');
            }
          } else {
            // Create accordion dynamically
            accordion = document.createElement('div');
            accordion.classList.add('accordion-content');

            // Extract content from hidden columns
            const accreditation = row.querySelector('td:nth-child(2)')?.innerText || 'N/A';
            const duration = row.querySelector('td:nth-child(3)')?.innerText || 'N/A';
            const credits = row.querySelector('td:nth-child(4)')?.innerText || 'N/A';

            // Populate accordion with content
            accordion.innerHTML = `
              <div>Accreditation: ${accreditation}</div>
              <div>Duration: ${duration}</div>
              <div>Credits: ${credits}</div>
            `;
            row.appendChild(accordion);

            // Force a reflow to ensure the height calculation works
            accordion.style.maxHeight = accordion.scrollHeight + "px";
            accordion.style.padding = "1rem";
            moreInfoSpan.classList.add('open');
          }
        });
      }
    });
  }

  console.log('sig')
  // Handle modal "Buy" button
  const buyButtons = document.querySelectorAll('.buy-btn button');
  if (buyButtons.length > 0) {
    buyButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const modal = document.getElementById("all-courses-modal");
        if (modal) {
          modal.style.display = "block";
        }
      });
    });
  }

  // Handle modal close button
  const closeButton = document.querySelector('.close-btn');
  if (closeButton) {
    closeButton.addEventListener("click", () => {
      const modal = document.getElementById("all-courses-modal");
      if (modal) {
        modal.style.display = "none";
      }
    });
  }

  // Handle outside modal click
  const modal = document.getElementById("all-courses-modal");
  if (modal) {
    window.addEventListener("click", (event) => {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    });
  }

  // Handle submit action for modal
  const submitButton = document.querySelector('.submit-btn');
  if (submitButton) {
    submitButton.addEventListener("click", () => {
      if (modal) {
        const buyingForMyself = document.getElementById("buying-for-myself")?.checked;
        const buyingForOthers = document.getElementById("buying-for-others")?.checked;

        if (buyingForMyself || buyingForOthers) {
          alert(`You selected: ${buyingForMyself ? "Buying for Myself" : ""} ${buyingForOthers ? "Buying for Others" : ""}`);
          modal.style.display = "none";
        } else {
          alert("Please select an option.");
        }
      }
    });
  }
});
