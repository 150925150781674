document.addEventListener('DOMContentLoaded', () => {
  // Function to scroll smoothly to a target section
  function scrollToSection(buttonSelector, targetSelector, offset = 0) {
      const button = document.querySelector(buttonSelector);
      const target = document.querySelector(targetSelector);

      if (button && target) {
          button.addEventListener('click', () => {
              const targetPosition = target.getBoundingClientRect().top + window.scrollY - offset;

              window.scrollTo({
                  top: targetPosition,
                  behavior: 'smooth',
              });
          });
      }
  }

  // Add event listeners for both buttons
  scrollToSection('.add-new-employee a', '#add-new-employee', 172); // Adjust offset as needed
  scrollToSection('.add-existing-employee a', '#add-existing-employee', 172); // Adjust offset as needed
});
